class SponsorBlock {
    constructor() {
        this.sponsorBlocks = document.querySelectorAll("[data-sponsor-block]");
        this.isTransitioning = false;
        this.interval = 7000; // 7 seconds
        this.init();
    }

    init() {
        if (!this.sponsorBlocks.length) return;

        this.sponsorBlocks.forEach((block) => {
            const sections = Array.from(
                block.querySelectorAll("[data-sponsor-section]")
            );

            // Only start rotation if there are at least 2 sections
            if (sections.length >= 2) {
                // Add pause on hover/focus
                block.addEventListener("mouseenter", () =>
                    this.pauseRotation()
                );
                block.addEventListener("mouseleave", () =>
                    this.resumeRotation()
                );
                block.addEventListener("focusin", () => this.pauseRotation());
                block.addEventListener("focusout", () => this.resumeRotation());

                this.startRotation(sections);
            }
        });
    }

    startRotation(sections) {
        this.sections = sections;
        this.currentIndex = 0;
        this.isPaused = false;

        this.rotationInterval = setInterval(() => {
            if (this.isTransitioning || this.isPaused) return;
            this.rotate();
        }, this.interval);
    }

    rotate() {
        this.isTransitioning = true;

        const currentSection = this.sections[this.currentIndex];
        this.currentIndex = (this.currentIndex + 1) % this.sections.length;
        const nextSection = this.sections[this.currentIndex];

        // Update ARIA hidden state
        currentSection.setAttribute("aria-hidden", "true");
        nextSection.setAttribute("aria-hidden", "false");

        // Fade out current section
        currentSection.classList.add("opacity-0");

        // After fade out completes, show next section
        setTimeout(() => {
            nextSection.classList.remove("opacity-0");
            this.isTransitioning = false;
        }, 500);
    }

    pauseRotation() {
        this.isPaused = true;
    }

    resumeRotation() {
        this.isPaused = false;
    }
}

export default SponsorBlock;
